import * as React from "react";
import { Link, graphql } from "gatsby";
import {
  Layout,
  SEO,
  CJournalJumbotron,
  CBreadCrumb,
  CJournalLead,
  CProfileMedia,
  CJournalText,
  CWideImgMedia,
  CFloatingImg,
  CBtnList,
  LWrap,
  CQuoteBox,
  CSingleImg,
  CChapterHeading,
  CLabelTextList,
  CJournalMedia,
  CMedia,
  CTileImg,
} from "../../../components/_index";

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  console.log(data.markdownRemark);
  return (
    <Layout isKv={false}>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CBreadCrumb
        data={{
          parent: [
            {
              label: "pickup",
              path: "/pickup/",
            },
          ],
          current: {
            label: frontmatter?.title.includes("-")
              ? frontmatter?.title.split("-")[0]
              : frontmatter?.title,
          },
        }}
      />
      <CJournalJumbotron
        data={{
          label: "ザ ロイヤルパークホテル アイコニック 東京汐留",
          date: "2022年03月31日",
          title: <>料理の奥にある心、わたしの“マイルール”</>,
          text: (
            <>
              ザ ロイヤルパークホテル アイコニック 東京汐留／池田 一之総料理長
              <br />
              <span className="u_referenceMark">
                2022年4月より横浜ロイヤルパークホテル調理部長に就任
              </span>
            </>
          ),
          img: {
            src: "/assets/images/journal/article03/kv.png",
          },
          imgSp: {
            src: "/assets/images/journal/article03/kv__sp.png",
          },
        }}
      />
      <div className="u_pb60">
        <LWrap size="small">
          <CJournalLead
            lead={
              <>
                <p>
                  ホテルのレストランで食事をしよう－。
                  <br />
                  この言葉を聞くとどんなことを連想するだろうか。いつもより贅沢な料理、記念日にはちゃんとした格好で、ちょっぴり緊張して、だけどワクワクとした気持ち……。
                </p>
                <p>
                  そんなふうに非日常の素晴らしさを体験できるのがホテルレストランの醍醐味なのではないだろうか。そして当然、その体験を演出してくれるのがレストランで働く人々だ。
                </p>
                <p>
                  今回はロイヤルパークホテルズの食の魅力を探るため、ザ
                  ロイヤルパークホテル アイコニック
                  東京汐留の池田一之総料理長に日頃から大切にしているマイルールを聞いた。
                </p>
              </>
            }
          />
        </LWrap>
      </div>
      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article03/img_chapter01.png",
            },
            imgSp: {
              src: "/assets/images/journal/article03/img_chapter01__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "01",
            },
            title: (
              <>
                <span>料理を提供する上で</span>
                <span>大切にしたいこと</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    高層ビルが立ち並ぶ東京・汐留—。
                    <br />
                    湾岸エリアや都内各所へのアクセスも良く、大企業の本拠地が集中するビジネス街だ。
                    そんなビル街にあるザ ロイヤルパークホテル アイコニック
                    東京汐留は、快適性と機能性を兼ね備えたシティホテルだ。
                  </p>
                  <p>
                    ホテルロビーは24階。エレベーターを降りた先に直営レストラン「オールデイダイニング
                    ハーモニー」がある。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article03/img_chapter01_01.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  笑顔で出迎えてくれたのは、池田一之総料理長。長年、仙台ロイヤルパークホテルの総料理長としてレストランを支えてきたベテランだ。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article03/img_chapter01_02.png",
              alt: "",
            }}
            caption={
              <>
                ぴかぴかに磨き上げられたキッチン内。整理整頓・清潔は池田総料理長の基本姿勢だ
              </>
            }
          />
          <CQuoteBox>
            <p className="u_mb0">#Rule 01</p>
            <h3 className="c_headingLv3">
              温かいものは温かく、冷たいものは冷たく
            </h3>
          </CQuoteBox>
          <CJournalText
            data={{
              text: (
                <>
                  朝出勤をすると綺麗なコックコートに身を包み、サロンをキュっと巻く。
                  柔らかく、穏やかな雰囲気を感じさせる池田総料理長の表情がキリリとした料理人の表情に変わるタイミングだ。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article03/img_chapter01_03.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    料理人としてのキャリアは40年に及ぶが、料理の世界に足を踏み入れたのは意外なきっかけだったそう。
                  </p>
                  <p>
                    「もともとは料理人ではなく、人と関わる仕事に就きたいと思っていたのですが、希望通りにいかず、そこで何がやりたいか考えてみたときにふと母のことを思い出しました」
                  </p>
                  <p>
                    料理上手な母の元に生まれ、優しい家庭料理のある環境で育ったことが人生の転機にふと思い出された。
                  </p>
                  <p>
                    「母はいつも家族のために、‟温かいものは温かく、冷たいものは冷たく”一番美味しい瞬間で料理を振舞ってくれました。そういった環境の中で育ってきたこともあってか、自然と食に関わる仕事っていいなと思い始めたのがきっかけです」
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article03/img_chapter01_04.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    19歳で料理人の道を志し上京。さまざまなホテルのレストランで経験を積んだ後、縁あって仙台ロイヤルパークホテルに就職したのは31歳のときだ。
                  </p>
                  <p>
                    「当時からロイヤルパークホテルズは“食のロイヤルパーク”と言われるくらい料理にとても力を入れていましたから、そこで経験を積めるというのは本当に嬉しかったです。ちゃんと美味しいものをお客様にご提供する、そういった理念が自分の大切にしている部分と重なりました」
                  </p>
                  <p>
                    たとえどんな料理やシーンでも、美味しいものを、美味しいままに届けたい。その想いには母親の背中を見て育ったことが根底にあるのだろう。
                  </p>
                </>
              ),
            }}
          />
          <CQuoteBox>
            <p className="u_mb0">#Rule 02</p>
            <h3 className="c_headingLv3">朝を特別な時間にする</h3>
          </CQuoteBox>
          <CJournalText
            data={{
              text: (
                <>
                  レストランの中へと足を運ぶと高い天井と大きな窓。そこから広がる景色に思わず「わぁ」という声をあげたくなる。心地よい朝の光が差し込み、窓の外には高層ビル群や浜離宮恩賜庭園、東京タワーなどの景色が広がっている。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article03/img_chapter01_05.png",
              alt: "",
            }}
            caption={
              <>
                広々とした空間が魅力的。24階から眺める景色を楽しみにリピートするお客様も多い
              </>
            }
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article03/img_chapter01_06.png",
              alt: "",
            }}
            caption={
              <>
                昼と夜で異なる雰囲気。天気の良い日には、東京タワーだけではなく遠目に富士山も見える
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>池田総料理長自身が最も好きな時間は朝だという。</p>
                  <p>
                    「朝は一番思考がシャキッとしていて、アイデアも浮かびます。朝食も大好きですね。一日の中で最初に食べるのが朝食ですから、どんなものを提供するか力が入ります。景色と料理をみなさん楽しみにしてきてくれます」
                  </p>
                  <p>
                    ハーモニーの朝食が他ホテルの朝食と一味違うのは、池田総料理長のこだわり。さまざまな試行錯誤を繰り返し、最終的にたどり着いたのが“朝本当に食べたくなる食事メニュー”だ。
                  </p>
                  <p>
                    「真っ先に浮かんだのが、グリーンアスパラガスでした。新鮮なアスパラガスをグリルして食べてもらいたい。グリル野菜ってなかなか家庭では作らないですよね。だからこそ、ホテルならではの調理として一つひとつの素材と向き合って作っています」
                  </p>
                  <p>
                    朝の時間を特別にすれば、一日中良い気分で過ごせるはず。ホテルで朝食を……たまにはそんな日があってもいいだろう。
                  </p>
                </>
              ),
            }}
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article03/img_chapter02.png",
            },
            imgSp: {
              src: "/assets/images/journal/article03/img_chapter02__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "02",
            },
            title: (
              <>
                <span>シンプルに、直感的に、</span>
                <span>そこに本当の</span>
                <span>おいしさがある</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CJournalText
            data={{
              text: (
                <>
                  朝食が終わり、レストランスタッフたちが手早くランチの準備に取り掛かる中、池田総料理長は一人机に向かいメニュー考案する。
                </>
              ),
            }}
          />
          <CQuoteBox>
            <p className="u_mb0">#Rule 03</p>
            <h3 className="c_headingLv3">五感で感じる料理を提供する</h3>
          </CQuoteBox>
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    池田総料理長が大切にしていることは何かと問うと「五感で楽しめること」という答えが返ってきた。
                  </p>
                  <p>
                    「食べた時にすぐに美味しいといえるような料理。直感的に季節の食材のおいしさを感じてもらえるように心がけています」
                  </p>
                  <p>
                    料理を小難しく考える必要はない。一口食べて、直感的に美味しいと感じられるかどうか。そんなシンプルさを軸に置いているのが池田総料理長流だ。
                  </p>
                  <p>
                    朝から夜までバラエティ豊かなメニューを提供しているが、そのインスピレーションは意外にも家庭料理番組や季節の食事から湧いてくるそうだ。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article03/img_chapter02_01.png",
              alt: "",
            }}
            caption={
              <>
                メニュー考案はキッチンスタッフにシェアするためスケッチしながら想像を膨らませる
              </>
            }
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article03/img_chapter02_02.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    「味付けはシンプルイズベスト。昔一緒に働いていたドイツ人料理人の方にとても刺激を受けました。できるだけ食材の味そのままを活かす味付けを意識しています」
                  </p>
                  <p>
                    その証拠にハーモニーのメニューは、そのほとんどにハーブが使われている。スパイスやハーブ、オリーブオイルなどを活用することで、限りなくシンプルな味付けを実現しているのだ。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article03/img_chapter02_03.png",
              alt: "",
            }}
            caption={<>新鮮なハーブの香りが食材の旨味を引き立たせてくれる</>}
          />
          <CQuoteBox>
            <p className="u_mb0">#Rule 04</p>
            <h3 className="c_headingLv3">“ワンアクション”を大切に</h3>
          </CQuoteBox>
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    「料理の演出で大切にしているのは、お客様の前で一つのアクションをすること。例えば、何かを隠しておいて開けてもらったり、最後にお客様自身で一手間かけていただいたり。そうすることで味の変化やサプライズ感を楽しんでもらいたいんです」
                  </p>
                  <p>
                    目の前に出された料理をただ食べてもらうのではなく、スタッフとの会話を楽しむこともレストランでの食事を楽しむひとつのエッセンス。その一工夫で料理に対する期待感もぐんと高まるのだ。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article03/img_chapter02_04.png",
              alt: "",
            }}
            caption={
              <>
                低温調理されたサーモンにバジルのソース。蕪のソースをお客様自身で垂らして
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  料理の包みをはがす、ソースを垂らす、混ぜる……。料理にはこの“ワクワク”が何よりもスパイスになったりする。ワンアクションの演出こそが、満足感を上げるのだ。
                </>
              ),
            }}
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article03/img_chapter03.png",
            },
            imgSp: {
              src: "/assets/images/journal/article03/img_chapter03__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "03",
            },
            title: (
              <>
                <span>料理の前後について</span>
                <span>考えることも、</span>
                <span>料理人の仕事</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CJournalText
            data={{
              text: (
                <>
                  ハーモニーの料理は実に多彩な食材が使われていて鮮やかだが、この食材の仕入れにも池田総料理長ならではの考えがあるようだ。
                </>
              ),
            }}
          />
          <CQuoteBox>
            <p className="u_mb0">#Rule 05</p>
            <h3 className="c_headingLv3">
              土地の食材を見つけ、活かし、見届ける
            </h3>
          </CQuoteBox>
          <CJournalText
            data={{
              text: (
                <>
                  「仙台にいたときは、東北六県の美味しい食材をたくさん使っていました。もちろんホテルグループのレストランですから、制限はあります。でも、その土地の美味しい食材を見つけて、なるべく使ってみたいという想いはありますね」
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article03/img_chapter03_01.png",
              alt: "",
            }}
            caption={<>見慣れない野菜に出会えるのもレストランならでは</>}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    地産地消という言葉をよく聞くが、東京でもそんなことが可能なのだろうか。
                  </p>
                  <p>
                    「仙台ほどではありませんが、東京や神奈川など関東近郊でも美味しい野菜はたくさんあります。神奈川ならば三浦野菜、東京ならば小松菜なんかも有名ですよね」
                  </p>
                  <p>
                    東京にいるとなかなか四季を感じにくい。しかし良い食材があるならば今後は東京だけの食材でコースが作れてしまうのでは？そんなふうに投げかけると、「そういうことにも挑戦したいですね」と嬉しそうに微笑んだ。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article03/img_chapter03_02.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    料理の“前”である食材選びにもこだわり抜いている池田総料理長だが、料理として提供された“後”のフードロスにも関心が高い。
                  </p>
                  <p>
                    「ブッフェで残った食材を捨てる……。そんな光景を目にしてきて、ずっと疑問に思っていました。世界には飢餓で苦しむ人がたくさんいるのに、料理人である自分の手で少しでも何かできないか、と。そんな想いがあるので、できるだけフルブッフェはやらないと決めています」
                  </p>
                  <p>
                    ハーモニーではワゴンで運ばれてくる料理を好きな分だけ頼むというスタイルのワゴンブッフェを採用している。
                    できるだけ無駄を出さない。何かひとつでもできることからと考えたどり着いたブッフェスタイルは、結果的にレストランの廃棄ロスにも通じている。道半ばだが、まだまだ池田総料理長の挑戦は続く。
                  </p>
                </>
              ),
            }}
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article03/img_chapter04.png",
            },
            imgSp: {
              src: "/assets/images/journal/article03/img_chapter04__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "04",
            },
            title: (
              <>
                <span>人が好き、お客様の</span>
                <span>“ハーモニー”になれたら</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CQuoteBox>
            <p className="u_mb0">#Rule 06</p>
            <h3 className="c_headingLv3">サービス、空間、料理は一体</h3>
          </CQuoteBox>
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    なぜ、これほどまでにハーモニーで過ごす時間は心地よいのだろう。その理由を池田総料理長は「チーム力」という言葉で表現する。
                  </p>
                  <p>
                    「本質的な部分ですが、料理だけじゃダメだと思うんです。サービスも空間も料理も全部で一つ。どれも細かいところまで気づけたら、“また来るね”が生まれるんじゃないでしょうか」
                  </p>
                  <p>
                    お店づくりは決して一人ではできない。心地よいお店でありたいという池田総料理長の言葉通り、キッチンもサービスもスタッフ同士のやり取りは見ていると気持ちが良いのだ。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article03/img_chapter04_01.png",
              alt: "",
            }}
            caption={
              <>
                「ワンハート（心はひとつ）」。サービススタッフとの会話中も笑顔が絶えずフラットだ
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  「サービスと空間、料理は一つであるべき」その言葉を裏付けるように、清潔な空間、心地よいサービス、お皿のセンスの良さ、料理の味、そのひとつ一つに私たちは感動する。この絶妙なバランスこそ、まさにハーモニーだ。
                </>
              ),
            }}
          />
          <CQuoteBox>
            <p className="u_mb0">#Rule 07</p>
            <h3 className="c_headingLv3">人が好き、その想いを大切に</h3>
          </CQuoteBox>
          <CJournalText
            data={{
              text: (
                <>
                  話の終盤、池田総料理長がぽつりとこんなことを話してくれた。
                  「やっぱり私自身、人が好きなのだと思います。ホテルで働く人の根底には、ホテルが好き、接客が好き、そして基本的に人が好きという想いが必ずあるはずです」
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article03/img_chapter04_02.png",
              alt: "",
            }}
            caption={
              <>
                ドイツ人シェフからの贈り物であるキュートなエプロン。お客様とのコミュニケーションのきっかけに
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    なるほど。だからホテルのレストランは私たちにとって特別な空間になるのだろう。子どもの頃に触れ合った特別な空間や時間、そして料理に、大人になった今また触れたくなるのも納得できる。
                    そこには、愛情を持って接してくれるスタッフや想いが込められた料理があるのだ。
                  </p>
                  <p>
                    料理人人生、40年。まだまだ挑戦したいことや取り組みたいことがあると目を輝かせる池田総料理長。2022年4月からは新天地となる横浜ロイヤルパークホテルで調理部長に就任する。どんな活躍を見せてくれるのか楽しみで仕方ない。
                  </p>
                  <p>またあの笑顔に会いに、訪れてみたくなった。</p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article03/img_chapter04_03.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <ul className="c_noteList">
                    <li>本記事・画像は2022年3月時点のものです。</li>
                  </ul>
                </>
              ),
            }}
          />

          <p className="u_fwb u_tac u_mt80">
            ザ ロイヤルパークホテル アイコニック 東京汐留
          </p>
          <CBtnList
            exClass="u_mt20"
            data={[
              {
                label: "ホテルサイト",
                color: "bgBlack",
                link: {
                  href: "https://www.royalparkhotels.co.jp/ic/tokyoshiodome/",
                  blank: true,
                },
                icon: "blank",
              },
            ]}
          />
        </LWrap>
      </section>

      <div className="l_sect02 u_bgGray">
        <LWrap>
          <CBtnList
            data={[
              {
                label: "ロイヤルパークホテルズJournal",
                link: {
                  href: "/pickup/#journal",
                },
                iconBefore: true,
                icon: "arrowReverse",
              },
            ]}
          />
        </LWrap>
      </div>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
